// 全局通用正则
const allNumberReg = /^[0-9]*$/;
// 手机
const phoneReg = /^1[3-9]\d{9}$/;
// 两位小数
const twoDecimals = /^([0-9]|[1-9]\d+)(\.\d{2})?$/;
// url 
const urlReg = /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/;

const bankNoReg = /^[A-Z0-9]+$/;

export { phoneReg, twoDecimals, allNumberReg, urlReg, bankNoReg }